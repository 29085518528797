/**
* api接口统一管理
*/
import { axios } from '@/utils/request'

export const listPolicyInfo = params => axios({
    url: '/api/order/policy/policyInfo/list',
    method: 'get',
    params,
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    }
})

export const addPolicyInfo = params => axios({
    url: '/api/order/policy/policyInfo/add',
    method: 'post',
    data: JSON.stringify(params),
    headers: {
        'Content-Type': 'application/json;charset=UTF-8'
    }
})

export const editPolicyInfo = params => axios({
    url: '/api/order/policy/policyInfo/edit',
    method: 'post',
    data: JSON.stringify(params),
    headers: {
        'Content-Type': 'application/json;charset=UTF-8'
    }
})

export const delPolicyInfo = params => axios({
    url:'/api/order/policy/policyInfo/del/' + params,
    method: 'post',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    }
})

export const selectByIdPolicyInfo = params => axios({
    url: '/api/order/policy/policyInfo/selectById/' + params,
    method: 'get',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    }
})

export const authPolicyInfo = params => axios({
    url: '/api/order/policy/policyInfo/auth?' + params,
    method: 'get',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    }
})

export const closePolicyInfo = params => axios({
    url: '/api/order/policy/policyInfo/close?' + params,
    method: 'get',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    }
})

export const getDismissTheReason = params => axios({
    url: '/api/order/policy/policyInfo/getDismissTheReason?' + params,
    method: 'get',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    }
})

export const getCloseTheReason = params => axios({
    url: '/api/order/policy/policyInfo/getCloseTheReason?' + params,
    method: 'get',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    }
})